import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import { colors } from '../../theme/colors';

type Props = {};

export const DocumentPreview: FC<Props> = () => {
  const data = useStaticQuery<GatsbyTypes.DocumentPreviewQueryQuery>(graphql`
    query DocumentPreviewQuery {
      contentfulDocumentSlides {
        first {
          gatsbyImageData
        }
        fourth {
          gatsbyImageData
        }
        second {
          gatsbyImageData
        }
        third {
          gatsbyImageData
        }
      }
    }
  `);

  const images = data.contentfulDocumentSlides;

  const [imageNumber, setImageNumber] = useState(0);

  const handleImageNumber = (index: number) => {
    setImageNumber(index);
  };

  return (
    // StaticImageにPropsを渡せないのでべた書き
    <div css={DocumentPreviewStyle}>
      <div css={SlidePreviewStyle}>
        {imageNumber === 0 && (
          <GatsbyImage
            image={images?.first?.gatsbyImageData!}
            alt='ページ1'
            css={{ width: '100%', maxWidth: 680 }}
          />
        )}
        {imageNumber === 1 && (
          <GatsbyImage
            image={images?.second?.gatsbyImageData!}
            alt='ページ2'
            css={{ width: '100%', maxWidth: 680 }}
          />
        )}
        {imageNumber === 2 && (
          <GatsbyImage
            image={images?.third?.gatsbyImageData!}
            alt='ページ3'
            css={{ width: '100%', maxWidth: 680 }}
          />
        )}
        {imageNumber === 3 && (
          <GatsbyImage
            image={images?.fourth?.gatsbyImageData!}
            alt='ページ4'
            css={{ width: '100%', maxWidth: 680 }}
          />
        )}
      </div>

      <div css={SlideButtonListStyle}>
        <button
          onClick={() => handleImageNumber(0)}
          css={SlideButtonStyle(imageNumber === 0)}
        >
          <GatsbyImage image={images?.first?.gatsbyImageData!} alt='ページ1' />
        </button>
        <button
          onClick={() => handleImageNumber(1)}
          css={SlideButtonStyle(imageNumber === 1)}
        >
          <GatsbyImage image={images?.second?.gatsbyImageData!} alt='ページ2' />
        </button>
        <button
          onClick={() => handleImageNumber(2)}
          css={SlideButtonStyle(imageNumber === 2)}
        >
          <GatsbyImage image={images?.third?.gatsbyImageData!} alt='ページ3' />
        </button>
        <button
          onClick={() => handleImageNumber(3)}
          css={SlideButtonStyle(imageNumber === 3)}
        >
          <GatsbyImage image={images?.fourth?.gatsbyImageData!} alt='ページ4' />
        </button>
      </div>
    </div>
  );
};

const DocumentPreviewStyle = css({
  maxWidth: 680,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

const SlidePreviewStyle = css({
  maxHeight: 382,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  borderRadius: 16,
  isolation: 'isolate',

  img: {
    borderRadius: 16,
    isolation: 'isolate',
  },
});

const SlideButtonListStyle = css({
  display: 'flex',
  gap: 16,

  '@media(max-width: 768px)': {
    gap: 5,
  },
});

const SlideButtonStyle = (isActive: boolean) =>
  css({
    borderRadius: 6,
    boxSizing: 'border-box',
    outline: isActive ? `3px solid  ${colors.primary}` : '1px solid #eeeeee',
    outlineOffset: isActive ? -3 : -1,

    '@media(max-width: 768px)': {
      outline: isActive ? `2px solid  ${colors.primary}` : '2px solid #eeeeee',
    },

    img: {
      borderRadius: 6,
      zIndex: -1,
    },
  });
