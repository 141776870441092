import { css } from '@emotion/react';
import { useState, ChangeEvent, FocusEvent } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import { HeadInner } from '../../components/Head';
import { PostConversionInfo, handlePostWithTrackingCV } from '../../util/post';
import { Layout } from '../../components/layout';
import { RegistrationLayout } from '../../components/forms/RegistrationLayout';
import { DocumentPreview } from '../../components/organisms/DocumentPreview';
import { colors } from '../../theme/colors';

export const Head = () => {
  return (
    <HeadInner
      path='/request-document/'
      title='資料請求'
      description='paild（ペイルド）の資料請求はこちらから'
    />
  );
};

const RequestDocumentPage = () => {
  const [agree, setAgree] = useState(false);

  const handleAgree = () => {
    setAgree(!agree);
  };

  const location = useLocation();
  const env = process.env.CONTEXT;

  const portalId = process.env.HUBSPOT_ID ? process.env.HUBSPOT_ID : '';
  const formId =
    env === 'dev' || env === 'stg'
      ? '1d73731c-0fc4-4cff-80db-a7051fdb3cbd'
      : 'b2952a8d-53de-435b-b7a1-71d0af2cb6a0';

  const [form, setForm] = useState<PostConversionInfo>({
    email: '',
    lastname: '',
    firstname: '',
    company: '',
    employees: '',
    department: '',
    jobtitle: '',
    phone: '',
    industry: '',
    employee_expense_use: false,
    cashless_petty_cash: false,
    saas_expense_management: false,
    pageUri: location.href,
    registration: false,
    request_document: true,
    service_codes: ['prepaid_card'],
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm({
      ...form,
      phone: e.currentTarget.value.replace(/-/g, ''),
    });
  };

  const requiredList = [
    'company',
    'employees',
    'email',
    'lastname',
    'firstname',
    'department',
    'phone',
    'industry',
  ];

  const onPostButtonClick = async () => {
    await handlePostWithTrackingCV(form, portalId, formId, document.title);
    navigate('/request-document/complete/');
  };

  const links = [{ name: '資料ダウンロード', path: '/request-document/' }];

  return (
    <Layout
      header='simple'
      background='simple'
      footer='simple'
      isBottomMenu={false}
      links={links}
    >
      <RegistrationLayout
        form={form}
        onChange={handleChange}
        onCheckBoxChange={handleCheck}
        onSelectChange={handleSelectChange}
        onBlur={handlePhoneBlur}
        agree={agree}
        handleAgree={handleAgree}
        requiredList={requiredList}
        onPostButtonClick={onPostButtonClick}
        isAndRegistration
      >
        <h2 css={RequestDocumentTitleStyle}>資料ダウンロード</h2>
        <DocumentPreview />
        <div css={RequestDocumentDescriptionStyle}>
          <p>
            法人支出の最適化を実現する「paild（ペイルド）」が提供するサービスの概要がわかる資料です。
            <br />
            paildの仕組みや活用事例、料金プランを説明していますので、ぜひご覧ください。
            <br />
            フォーム送信後、ご入力いただいたメールアドレスに資料が届きます。
          </p>
        </div>
        <div css={RequestDocumentOverviewWrapperStyle}>
          <h3 css={RequestDocumentOverviewHeadingStyle}>
            本資料を通して、こんなことがわかります
          </h3>
          <ul css={RequestDocumentOverviewListStyle}>
            <li css={RequestDocumentOverviewStyle}>paildのサービス内容</li>
            <li css={RequestDocumentOverviewStyle}>活用例</li>
            <li css={RequestDocumentOverviewStyle}>料金プラン</li>
          </ul>
        </div>
        <div css={RequestDocumentFlowStyle}>
          <p>
            フォームに必要事項をご記入の上、送信ボタンを押してください。
            <br />
            フォーム入力後、メールにて資料を送付いたします。
          </p>
        </div>
      </RegistrationLayout>
    </Layout>
  );
};

const RequestDocumentTitleStyle = css({
  color: colors.black,
  fontSize: 40,

  '@media(max-width: 1119px)': {
    fontSize: 28,
  },
});

const RequestDocumentDescriptionStyle = css({
  p: { fontSize: 16, lineHeight: 2, letterSpacing: 0.05 },

  '@media(max-width: 768px)': {
    br: { display: 'none' },
    p: { fontSize: 14 },
  },
});
const RequestDocumentOverviewWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  lineHeight: 1,
});

const RequestDocumentOverviewHeadingStyle = css({
  fontSize: 28,
  fontWeight: 700,
  lineHeight: 1,
  color: colors.primary,

  '@media(max-width: 768px)': {
    fontSize: 24,
  },
});

const RequestDocumentOverviewListStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  paddingLeft: 22,

  '@media(max-width: 768px)': {
    gap: 12,
  },
});

const RequestDocumentOverviewStyle = css({
  fontSize: 16,

  '@media(max-width: 768px)': {
    fontSize: 14,
  },

  '&::marker': {
    color: colors.primary,
  },
});

const RequestDocumentFlowStyle = css({
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  border: `2px solid ${colors.disabled}`,
  borderRadius: 16,

  p: { fontSize: 16, fontWeight: 500 },

  '@media(max-width: 768px)': {
    br: { display: 'none' },
    p: { fontSize: 14 },
  },
});

export default RequestDocumentPage;
